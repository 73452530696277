import HdxxCard from './HdxxCard.vue';
import {ref, reactive, toRefs, computed, defineComponent,onBeforeMount, onMounted, getCurrentInstance, watch, provide,nextTick} from 'vue';
import HdxxListUtil,{IHdxxListDataObj} from './hdxxListUtil';
import language from './hdxxLanguage'
export default defineComponent ({
    name: 'HdxxList',
    setup(){
        const {proxy}=getCurrentInstance() as any;
        const utils=proxy.utils;
        proxy.addLangProps(language);
        let dataObj:IHdxxListDataObj=reactive<IHdxxListDataObj>({
            pageListRef:null,
            utilInst:{} as any,
            refMap:new Map(),
            pageList: {
                queryParam: {},
                modelComp: HdxxCard,
                cardFrom:'HdxxList',
                modelMethod: utils.OrderProviderApi.buildUrl('/hdxx/pageData')
            },
            otherParams:{

            },
        })
        onBeforeMount(()=>{
            dataObj.utilInst=new HdxxListUtil(proxy,dataObj);
        })
        onMounted(()=>{
            nextTick(async()=>{
                await dataObj.utilInst.buildSelectData();
            })
        })
        //---------------------------computed------------
        //下拉选择
        const comboSelect=computed(()=>{
            return (params:any) => {
                if(params && 'status'==params.comboId){
                    return [{value:0,label:'未发运'},{value:1,label:'已维护货代信息'},{value:2,label:'已启票'},{value:3,label:'已发运通知'},{value:4,label:'已上传报关单'}]
                }
            }
        })

        const formatPageInfo=(options:any)=>{
            return options;
        }

        return{
            ...toRefs(dataObj),comboSelect,formatPageInfo
        }
    }
});